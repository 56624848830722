import { pushToDataLayer } from "../data/data-layer.facade";
import { normalizeWhitespace } from "@shared/Utils";
import { attachClickEventListener } from "@shared/Utils/analytics-utils";

export type GA4FAQEvent = {
  event: "mobilabonnement";
  element_category: "faq_dropdown";
  element_cta: string;
};

const FAQ_DATA_ID = "faq_dropdown";

const getAccordionTitleWithStatus = (target: HTMLElement): string => {
  const buttonElement = target.closest<HTMLElement>("button");
  const status = buttonElement?.getAttribute("aria-expanded") === "true" ? "close" : "open";
  const ctaContent = buttonElement?.getAttribute("data-analytics-cta") ?? buttonElement?.textContent ?? "";
  return normalizeWhitespace(`${ctaContent} - ${status}`);
};

export const bootstrapFAQAnalytics = () => {
  const items = document.querySelectorAll(`[data-id$='${FAQ_DATA_ID}'] button`);

  attachClickEventListener(items, (event) => {
    const target = event.target as HTMLElement;
    const cta = getAccordionTitleWithStatus(target);

    const faqEvent: GA4FAQEvent = {
      event: "mobilabonnement",
      element_category: "faq_dropdown",
      element_cta: cta,
    };

    pushToDataLayer(faqEvent);
  });
};
